import React from "react";
import Placeholder from "../../../../core/Components/Placeholder";
import {
  calculateSpacing,
  getHorizontalAlign,
  getVerticalAlign,
  joinClasses,
} from "../../../../utils/getContainerClasses";

const ThreeColumnContainer = ({ rendering, fields }) => {
  return (
    <section
      id={fields.id?.value}
      className={joinClasses(
        "column-container",
        fields.backgroundColor?.name && `bg-${fields.backgroundColor.name}`,
        fields.roundedCorners?.value && "rounded",
        fields.outerClassName?.value,
        calculateSpacing(fields),
      )}
    >
      <div className={fields.useContainer?.value ? "container" : undefined}>
        <div
          className={joinClasses(
            "row",
            fields.useContainer?.value && "gy-6 gy-md-0",
            getHorizontalAlign(fields.horizontalAlign?.name),
            getVerticalAlign(fields.verticalAlign?.name),
          )}
        >
          <div className={`col-md-${fields.col1?.value}`}>
            <Placeholder name="three-column-content-1" rendering={rendering} />
          </div>
          <div className={`col-md-${fields.col2?.value}`}>
            <Placeholder name="three-column-content-2" rendering={rendering} />
          </div>
          <div className={`col-md-${fields.col3?.value}`}>
            <Placeholder name="three-column-content-3" rendering={rendering} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThreeColumnContainer;
